#secondary-navigation {
  &.secondary-navigation--desktop {
    position: absolute;
    top: 70px;
    bottom: 0;
    left: 250px;
    width: 270px;
    background: #F7F9FC;
   // Setting z-index to 2 because the edit-icon used elsewhere with z-index: 1
    z-index: 2; 
    box-shadow: 0 4px 20px #00000014;
  }

  &.secondary-navigation--mobile {
    position: absolute;
    top: 70px;
    left: 0;
    width: 100%;
    height: calc(100% - 70px);
    background: #F7F9FC;
    z-index: 2;
    padding: unset;
  }

  .secondary-navigation__title-container {
    height: 70px;
    padding-inline: 24px;
    padding-block: 20px;
    border-bottom: 1px solid #E6EAF5;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    gap: 16px;
    color: #6b7a93;

    span {
      text-transform: uppercase;
      font-size: 12px;
    }
  }

  .secondary-navigation__link {
    display: flex;
    align-items: center;
    height: 39px;
    color: #6b7a93;
    padding-inline: 15px;
  }

  .secondary-navigation__close-button {
    border: 1px solid #C2CCE7;
    background: white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}